/**
 * CkButton Component
*
* `CkButton` is a wrapper component around Ant Design's Button that provides 
* additional customization options. It accepts all properties of the AntDesign 
* Button as well as additional properties.
*
* @component
* @example
* // This is how you can use the CkButton component
* <CkButton variant="primary" color="danger">Click Me</CkButton>
*
* @extends {ButtonProps}
*
* @property {ReactNode} children - Optional: Content displayed inside the button
* @property {string} color - Optional: Button color, defaults to "primary". Can be any of "primary", "secondary", "mariner", "danger", "white"
* @property {string} variant - Optional: AntDesign type prop to specify button style. Defaults to "primary"
* @property {string} size - Optional: The size of the button. Can be "large", "middle", or "small"
* @property {CkIconNames | ReactNode} icon - Optional: An icon component to display in the button
* @property {boolean} block - Optional: If true, button will take up full width of parent. Defaults to true
* @property {boolean} underlined - Optional: If true, text on button is underlined
* @property {boolean} triggerGTMOnClick - Optional: If true, Google Tag Manager event is triggered when button is clicked
*/
import React, { FC, ReactNode } from "react";
import { Button, ButtonProps } from "antd";
import CkIcon, { CkIconNames } from "../../atoms/CkIcon";
import { gtmSetId } from "../../../../utilities/loadGoogleTagManager";
import "./styles.css";

export type TCKButtonVariants = "primary" | "secondary" | "dashed" | "link" | "text"; 
type TTypes = "default" | "primary" | "dashed" | "link" | "text";

export interface CkButtonProps extends ButtonProps {
  /**
   * Optional: Content displayed inside the button
   */
  children?: ReactNode;
  /**
   * For internal purposes, variant prop will be translated into antd type prop in this way:
   * variant   => type
   * primary   => default
   * secondary => primary
   * dashed    => dashed
   * link      => link
   * text      => text
   */
  variant?: TCKButtonVariants;
  /**
   * Optional: Button color, defaults to "primary". Can be any of "primary", "secondary", "mariner", "danger", "white"
   */
  color?: "primary" | "secondary" | "mariner" | "danger" | "white";
  /**
   * Optional: The size of the button. Can be "large", "middle", or "small"
   */
  size?: "large" | "middle" | "small";
  /**
   * Optional:
   * If a string is provided, component will try to import CkIcon element. 
   * If ReactNode is provided, this will be used.
   */
  icon?: CkIconNames | ReactNode;
  /**
   * Optional: If true, button will take up full width of parent. Defaults to true
   */
  block?: boolean;
  /**
   * Optional: If true, text on button is underlined
   */
  underlined?: boolean;
  /**
   * Optional: If true, Google Tag Manager event is triggered when button is clicked
   */
  triggerGTMOnClick?: boolean;
}

const translateType = (variant: TCKButtonVariants) : TTypes  => {
  if (variant === "primary") return "default";
  if (variant === "secondary") return "primary";
  return variant;
}

/**
 * Extends AntD Button props
 * See full doc on https://4x.ant.design/components/button/
 */
const CkButton: FC<CkButtonProps> = ({children, block = true, underlined, color = "primary", variant = "primary", size = "large", icon, className, triggerGTMOnClick, ...props}) => (
  <Button 
    className={["ck-button", color, ...(icon ? ["iconed"] : []),...(underlined ? ["underlined"] : []),...(className ? [className] : [])].join(" ")}
    type={translateType(variant)}  
    size={size}
    {...(block ? {block: true} : {})}
    {...(icon && typeof icon === "string" ? {icon: <CkIcon name={icon as CkIconNames} />} : {})}
    {...(icon && typeof icon !== "string" ? {icon} : {})}
    {...(props.id && triggerGTMOnClick ? {onClick: (e) => {
      gtmSetId(e.currentTarget);
      props?.onClick && props.onClick(e); 
    }} : {})}
    {...props}
  >
    {children && children}
  </Button>
);

export default CkButton;
